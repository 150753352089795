<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">        
    <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5"> 
      <h3 class="text-dark-blue">Subscription plans</h3>
      <span class="text-muted border-bottom pb-2 margin-top-subtitle"> Choose the plan that's right for your team</span>
      <div class=" form-switch">
        <label class="form-check-label me-5" for="flexSwitchCheckDefault">Annual / Monthly</label>
        <input class="switchToggle form-check-input" type="checkbox" id="flexSwitchCheckDefault">                           
      </div>  
      <div class="container m-0">
        <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">
          <div class="col">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Free</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$0<small class="text-muted fw-light">/mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Time tracking</li>
                  <li>Activity levels</li>
                  <li>Limited screenshots</li>
                  <li>Limited payments</li>
                </ul>
                <button type="button" class="border-blue btn-plan-w text-dark-blue w-100 btn btn-md btn-outline-primary">Continue</button>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4 rounded-3 shadow-sm">
              <div class="card-header py-3">
                <h4 class="my-0 fw-normal">Basic</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$5<small class="text-muted fw-light">/mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Time tracking</li>
                  <li>Activity levels</li>
                  <li>Limited screenshots</li>
                  <li>Limited payments</li>
                  <li>1 integration</li>
                  <li>24 hour support</li>
                  <li>Per user settings</li>                                
                </ul>
                <!-- Button trigger modal -->
                <button type="button" class="btn-plan-b w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Get started
                </button>
                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">New credit card</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                      </div>
                      <div class="modal-body" align="left">
                        <div class="col-md-6 ">
                          Your Plan: <span class="special-badget-status">Basic Active</span>
                        </div>                                       
                        <form class="row g-3 mt-1" align="left">
                          <div class="col-md-6">
                            <label for="validationDefault01" class="form-label">Name on card</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="Johan Sebastian">
                          </div>
                          <div class="col-md-6">
                            <label for="validationDefault01" class="form-label">Card Number</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="3566 0000 20000 0000">
                          </div>
                          <div class="col-md-4">
                            <label for="validationDefault01" class="form-label">Expires</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="02/23">
                          </div>
                          <div class="col-md-4">
                            <label for="validationDefault01" class="form-label">Security code</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="123">
                          </div>
                          <div class="col-md-4">
                            <label for="validationDefault01" class="form-label">ZIP/Postal Code</label>
                            <input type="text" class="form-control" id="validationDefault01" required placeholder="90210">
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn-plan-b btn btn-primary">Save</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4 rounded-3 shadow-sm border-primary">
              <div class="bg-card-b card-header py-3 text-white border-primary">
                <h4 class="my-0 fw-normal">Enterprise</h4>
              </div>
              <div class="card-body">
                <h1 class="card-title pricing-card-title">$29<small class="text-muted fw-light">/mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4">
                  <li>30 users included</li>
                  <li>15 GB of storage</li>
                  <li>Phone and email support</li>
                  <li>Help center access</li>
                </ul>
                <button class="btn-plan-b w-100 btn btn-md btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Contact us</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>        
  </main>
</template>

<script>
  export default
  {
    name: 'Plan' 
  }
</script>

<style scoped>
.margin-top-subtitle{
    margin-top:1px;
}

.text-dark-blue {
    color: var(--color-six);
}
.border-blue{
    border: 1px solid var(--color-six)
}
.btn-plan-w{
    background-color: #FFFFFF;
    color:  var(--color-six);
}
.btn-plan-w:hover{
    background-color: var(--color-six);
    color:  #FFF;
}
.btn-plan-b{
    background-color: var(--color-six);
    color: #FFF;
}
.bg-card-b{
    background-color: var(--color-six);
}
.inline-dis{
    display: inline !important;
}
</style>
